import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { MapPin, DollarSign, Calendar, Briefcase, ExternalLink, ArrowLeft } from 'lucide-react';
import { getJobById } from '../api/jobs';
import { Job } from '../types/job';
import DOMPurify from 'dompurify';
import { format } from 'date-fns';
import { LoadingSpinner } from '../components/LoadingSpinner';

export function JobDetails() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [job, setJob] = useState<Job | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchJobDetails() {
      if (!id) return;
      try {
        setIsLoading(true);
        const jobData = await getJobById(id);
        setJob(jobData);
      } catch (error) {
        console.error('Error fetching job details:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchJobDetails();
  }, [id]);

  const formatDescription = (html: string) => {
    const cleanHtml = DOMPurify.sanitize(html);
    return { __html: cleanHtml };
  };

  if (isLoading) return <LoadingSpinner />;

  if (!job) {
    return (
      <div className="container mx-auto px-4 py-8 text-center">
        <h1 className="text-2xl font-bold mb-4">Job Not Found</h1>
        <p className="mb-4">Sorry, the job you're looking for doesn't exist.</p>
        <Button onClick={() => navigate('/search')}>Back to Search</Button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Button 
        variant="outline" 
        className="mb-4"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft className="mr-2 h-4 w-4" />
        Back to Search Results
      </Button>
      <Card className="max-w-4xl mx-auto rounded-2xl transition-all duration-300 ease-in-out hover:shadow-xl">
        <CardHeader className="bg-primary text-primary-foreground p-6 rounded-t-2xl">
          <div className="flex items-center">
            <div className="w-24 h-24 mr-6 rounded-xl bg-white p-2 flex items-center justify-center">
              <img src={job.logo} alt={`${job.company} logo`} className="max-w-full max-h-full object-contain" />
            </div>
            <div>
              <CardTitle className="text-3xl font-bold mb-2">{job.position}</CardTitle>
              <p className="text-xl">{job.company}</p>
            </div>
          </div>
        </CardHeader>
        <CardContent className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            <div className="flex items-center">
              <MapPin className="mr-2 h-5 w-5 text-primary" />
              <span>{job.location}</span>
            </div>
            <div className="flex items-center">
              <DollarSign className="mr-2 h-5 w-5 text-primary" />
              <span>{job.experience}</span>
            </div>
            <div className="flex items-center">
              <Calendar className="mr-2 h-5 w-5 text-primary" />
              <span>Posted on: {format(new Date(job.date_added), 'MMMM d, yyyy')}</span>
            </div>
            {job.type && (
              <div className="flex items-center">
                <Briefcase className="mr-2 h-5 w-5 text-primary" />
                <span>{job.type}</span>
              </div>
            )}
          </div>
          <div className="mb-8">
            <h3 className="text-2xl font-semibold mb-4">Job Description</h3>
            <div 
              className="prose prose-sm max-w-none dark:prose-invert"
              dangerouslySetInnerHTML={formatDescription(job.description)}
            />
          </div>
          <Button 
            onClick={() => window.open(job.url, '_blank')}
            className="w-full py-6 text-lg rounded-full transition-all duration-300 ease-in-out hover:bg-primary-foreground hover:text-primary"
          >
            <ExternalLink className="mr-2 h-5 w-5" />
            Apply Now
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}

export default JobDetails;