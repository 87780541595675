'use client'

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../components/ui/card";
import { Badge } from "../components/ui/badge";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "../components/ui/sheet";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/select";
import { Slider } from "../components/ui/slider";
import { Search, Filter, TrendingUp, MapPin, DollarSign, Calendar, BookmarkPlus, Rocket } from 'lucide-react';
import { fetchJobs } from '../api/jobs';
import { Job } from '../types/job';
import { LoadingSpinner } from '../components/LoadingSpinner';

const MIN_SEARCH_CHARS = 2;

export function HomePage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [featuredJobs, setFeaturedJobs] = useState<Job[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [savedJobs, setSavedJobs] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function loadFeaturedJobs() {
      try {
        setIsLoading(true);
        const response = await fetchJobs(1, 6);
        setFeaturedJobs(response.jobs);
        
        const uniqueCategories = Array.from(new Set(response.jobs.map(job => job.category).filter((category): category is string => !!category)));
        setCategories(uniqueCategories);
      } catch (error) {
        console.error('Failed to load featured jobs:', error);
      } finally {
        setIsLoading(false);
      }
    }

    loadFeaturedJobs();
  }, []);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchTerm.trim().length > MIN_SEARCH_CHARS) {
      navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
    }
  };

  const handleExploreAllJobs = () => {
    navigate('/search');
  };

  const toggleSaveJob = (jobId: string) => {
    setSavedJobs(prev => 
      prev.includes(jobId) ? prev.filter(id => id !== jobId) : [...prev, jobId]
    );
  };

  return (
    <div className="min-h-screen bg-background text-foreground">
      <main className="container mx-auto px-4 py-8">
        <section className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Find Your Dream Job</h2>
          <p className="text-xl text-muted-foreground mb-8">Discover opportunities that match your skills and aspirations</p>
          <form onSubmit={handleSearch} className="flex max-w-2xl mx-auto">
            <Input 
              className="flex-grow mr-2 rounded-full"
              placeholder="Job title, keywords, or company" 
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Sheet>
              <SheetTrigger asChild>
                <Button type="button" variant="outline" className="rounded-full mr-2">
                  <Filter className="mr-2 h-4 w-4" /> Filters
                </Button>
              </SheetTrigger>
              <SheetContent>
                <SheetHeader>
                  <SheetTitle>Search Filters</SheetTitle>
                  <SheetDescription>Refine your job search</SheetDescription>
                </SheetHeader>
                <div className="py-4 space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="location">Location</label>
                    <Input id="location" placeholder="City, State, or Remote" />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="job-type">Job Type</label>
                    <Select>
                      <SelectTrigger id="job-type">
                        <SelectValue placeholder="Select job type" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="full-time">Full Time</SelectItem>
                        <SelectItem value="part-time">Part Time</SelectItem>
                        <SelectItem value="contract">Contract</SelectItem>
                        <SelectItem value="internship">Internship</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Salary Range</label>
                    <Slider
                      defaultValue={[50000, 150000]}
                      max={200000}
                      step={1000}
                      className="mb-2"
                    />
                    <div className="flex justify-between text-sm text-muted-foreground">
                      <span>$50,000</span>
                      <span>$200,000+</span>
                    </div>
                  </div>
                </div>
              </SheetContent>
            </Sheet>
            <Button type="submit" className="rounded-full" disabled={searchTerm.trim().length <= MIN_SEARCH_CHARS}>
              <Search className="mr-2 h-4 w-4" /> Search
            </Button>
          </form>
        </section>

        {categories.length > 0 && (
          <section className="mb-12">
            <h2 className="text-2xl font-semibold mb-4 text-center">Popular Categories</h2>
            <div className="flex flex-wrap gap-4 justify-center">
              {categories.map((category) => (
                <Button 
                  key={category} 
                  variant="outline" 
                  className="rounded-full bg-white dark:bg-gray-700 hover:bg-sky-50 dark:hover:bg-sky-900 text-sky-800 dark:text-sky-100 border-sky-200 dark:border-sky-700 hover:border-sky-300 dark:hover:border-sky-500 transition-all duration-300 shadow-md hover:shadow-lg transform hover:-translate-y-1"
                  onClick={() => navigate(`/search?category=${encodeURIComponent(category)}`)}
                >
                  {category}
                </Button>
              ))}
            </div>
          </section>
        )}

        <section className="mb-12">
          <h2 className="text-2xl font-semibold mb-4 text-center">Featured Jobs</h2>
          {isLoading ? (
            <LoadingSpinner />
          ) : featuredJobs.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {featuredJobs.map((job) => (
                <Card key={job._id} className="transition-all duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1">
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <img src={job.logo} alt={`${job.company} logo`} className="w-10 h-10 mr-3 object-contain" />
                        <CardTitle>{job.position}</CardTitle>
                      </div>
                      <Badge variant="secondary">New</Badge>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <p className="text-muted-foreground mb-4">{job.company}</p>
                    <div className="space-y-2 text-sm">
                      <div className="flex items-center">
                        <MapPin className="mr-2 h-4 w-4" /> {job.location}
                      </div>
                      <div className="flex items-center">
                        <DollarSign className="mr-2 h-4 w-4" /> {job.experience}
                      </div>
                      <div className="flex items-center">
                        <Calendar className="mr-2 h-4 w-4" /> Posted {new Date(job.date_added).toLocaleDateString()}
                      </div>
                    </div>
                  </CardContent>
                  <CardFooter className="flex justify-between">
                    <Button variant="outline" size="sm" onClick={() => navigate(`/job/${job._id}`)}>View Details</Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => toggleSaveJob(job._id)}
                    >
                      <BookmarkPlus className={`h-5 w-5 ${savedJobs.includes(job._id) ? 'text-primary' : ''}`} />
                    </Button>
                  </CardFooter>
                </Card>
              ))}
            </div>
          ) : (
            <p className="text-center text-muted-foreground">No featured jobs available at the moment.</p>
          )}
          <div className="flex justify-center mt-8">
            <Button 
              variant="outline" 
              onClick={handleExploreAllJobs}
              className="rounded-full transition-all duration-300 ease-in-out hover:bg-primary hover:text-primary-foreground"
            >
              Explore All Jobs
            </Button>
          </div>
        </section>

        <section className="text-center bg-gradient-to-r from-primary to-primary-dark text-primary-foreground rounded-2xl p-8 transition-all duration-300 ease-in-out hover:shadow-xl">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="mb-6 md:mb-0 md:mr-6 text-left">
              <h3 className="text-3xl font-bold mb-4">Boost Your Job Search</h3>
              <p className="text-xl mb-6">Get personalized job recommendations and stand out to employers.</p>
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
              <Button 
                size="lg" 
                variant="secondary"
                className="rounded-full transition-all duration-300 ease-in-out hover:bg-secondary-foreground hover:text-secondary"
              >
                <Rocket className="mr-2 h-5 w-5" />
                Create Your Profile
              </Button>
              <Button 
                size="lg" 
                variant="outline"
                className="rounded-full bg-white text-primary transition-all duration-300 ease-in-out hover:bg-primary-foreground hover:text-primary border-white hover:border-primary"
              >
                <TrendingUp className="mr-2 h-5 w-5" />
                Explore Career Tips
              </Button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default HomePage;
