import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Job } from '../types/job';
import { Card, CardContent, CardFooter } from "./ui/card";
import { Button } from "./ui/button";
import { Badge } from "./ui/badge";
import { MapPin, DollarSign, Calendar, BookmarkPlus } from 'lucide-react';
import { format } from 'date-fns';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./ui/tooltip";

interface JobCardProps {
  job: Job;
  onSave: (jobId: string) => void;
  isSaved: boolean;
}

const TruncatedText: React.FC<{ text: string; limit: number }> = ({ text, limit }) => {
  const truncated = text.length > limit ? text.slice(0, limit) + '...' : text;
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className="cursor-help">{truncated}</span>
        </TooltipTrigger>
        <TooltipContent>
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const JobCard: React.FC<JobCardProps> = ({ job, onSave, isSaved }) => {
  const navigate = useNavigate();

  const handleViewDetails = () => {
    navigate(`/job/${job._id}`);
  };

  return (
    <Card className="h-full flex flex-col justify-between transition-all duration-300 hover:shadow-lg hover:-translate-y-1">
      <CardContent className="pt-6">
        <div className="flex items-center justify-between mb-2">
          <img src={job.logo} alt={`${job.company} logo`} className="w-8 h-8 object-contain" />
          <Badge variant="secondary">New</Badge>
        </div>
        <h3 className="text-lg font-semibold mb-1">
          <TruncatedText text={job.position} limit={30} />
        </h3>
        <p className="text-sm text-muted-foreground mb-2">
          <TruncatedText text={job.company} limit={25} />
        </p>
        <div className="space-y-1 text-sm">
          <div className="flex items-center">
            <MapPin className="mr-2 h-3 w-3 text-muted-foreground" />
            <TruncatedText text={job.location} limit={20} />
          </div>
          <div className="flex items-center">
            <DollarSign className="mr-2 h-3 w-3 text-muted-foreground" />
            <TruncatedText text={job.experience} limit={20} />
          </div>
          <div className="flex items-center">
            <Calendar className="mr-2 h-3 w-3 text-muted-foreground" />
            <span>Posted {format(new Date(job.date_added), 'MMM dd, yyyy')}</span>
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-between pt-4">
        <Button variant="outline" size="sm" onClick={handleViewDetails}>
          View Details
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => onSave(job._id)}
          className={isSaved ? "text-primary" : ""}
        >
          <BookmarkPlus className="h-5 w-5" />
        </Button>
      </CardFooter>
    </Card>
  );
};