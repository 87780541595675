import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Card, CardContent, CardFooter, CardHeader } from "../components/ui/card";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "../components/ui/sheet";
import { Search, Filter } from 'lucide-react';
import { searchJobs, fetchJobs } from '../api/jobs'; // Add fetchJobs here
import { Job } from '../types/job';
import { Skeleton } from "../components/ui/skeleton";
import { JobCard } from '../components/JobCard';

const JOBS_PER_PAGE = 20;
const MIN_SEARCH_CHARS = 2;

const JobCardSkeleton = () => (
  <Card className="animate-pulse rounded-xl">
    <CardHeader>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <Skeleton className="w-10 h-10 rounded-full mr-3" />
          <Skeleton className="h-6 w-32" />
        </div>
        <Skeleton className="h-5 w-16" />
      </div>
    </CardHeader>
    <CardContent>
      <Skeleton className="h-4 w-3/4 mb-4" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-2/3" />
      </div>
    </CardContent>
    <CardFooter className="flex justify-between">
      <Skeleton className="h-9 w-24" />
      <Skeleton className="h-9 w-9 rounded-full" />
    </CardFooter>
  </Card>
);

export function SearchResults() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [searchTerm, setSearchTerm] = useState(searchParams.get('q') || '');
  const company = searchParams.get('company') || '';
  const category = searchParams.get('category') || '';
  const [jobs, setJobs] = useState<Job[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [savedJobs, setSavedJobs] = useState<string[]>([]);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastJobElementRef = useCallback((node: HTMLDivElement | null) => {
    if (isLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  const loadJobs = useCallback(async () => {
    setIsLoading(true);
    try {
      let response;
      if (searchTerm || company || category) {
        response = await searchJobs(searchTerm, company, category, page, JOBS_PER_PAGE);
      } else {
        response = await fetchJobs(page, JOBS_PER_PAGE);
      }
      
      setJobs(prevJobs => page === 1 ? response.jobs : [...prevJobs, ...response.jobs]);
      setTotalJobs(response.total_jobs);
      setHasMore(response.jobs.length === JOBS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, company, category, page]);

  useEffect(() => {
    setJobs([]);
    setPage(1);
    setHasMore(true);
    loadJobs();
  }, [searchTerm, company, category, loadJobs]);

  useEffect(() => {
    if (page > 1) {
      loadJobs();
    }
  }, [page, loadJobs]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchTerm.trim().length > MIN_SEARCH_CHARS) {
      navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
      setPage(1);
      setJobs([]);
      setHasMore(true);
      loadJobs();
    }
  };

  const toggleSaveJob = (jobId: string) => {
    setSavedJobs(prev => 
      prev.includes(jobId) ? prev.filter(id => id !== jobId) : [...prev, jobId]
    );
  };

  const pageTitle = searchTerm
    ? `Search Results for "${searchTerm}"`
    : company
    ? `Jobs at ${company}`
    : category
    ? `Jobs in ${category}`
    : "All Job Listings";

  return (
    <div className="min-h-screen bg-background text-foreground">
      <main className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center mb-8">{pageTitle}</h1>
        
        <form onSubmit={handleSearch} className="flex max-w-2xl mx-auto mb-8">
          <Input 
            className="flex-grow mr-2 rounded-full" 
            placeholder="Job title, keywords, or company" 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Sheet>
            <SheetTrigger asChild>
              <Button type="button" variant="outline" className="rounded-full">
                <Filter className="mr-2 h-4 w-4" /> Filters
              </Button>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Search Filters</SheetTitle>
                <SheetDescription>Refine your job search</SheetDescription>
              </SheetHeader>
              {/* Add filter options similar to HomePage */}
            </SheetContent>
          </Sheet>
          <Button type="submit" className="ml-2 rounded-full" disabled={searchTerm.trim().length <= MIN_SEARCH_CHARS}>
            <Search className="mr-2 h-4 w-4" /> Search
          </Button>
        </form>

        <p className="text-xl text-center mb-8">
          Found <span className="font-semibold">{totalJobs}</span> jobs
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {isLoading && jobs.length === 0
            ? Array.from({ length: 8 }).map((_, index) => (
                <JobCardSkeleton key={index} />
              ))
            : jobs.map((job, index) => (
                <div key={job._id} ref={index === jobs.length - 1 ? lastJobElementRef : null}>
                  <JobCard
                    job={job}
                    onSave={toggleSaveJob}
                    isSaved={savedJobs.includes(job._id)}
                  />
                </div>
              ))}
        </div>

        {isLoading && jobs.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-8">
            {Array.from({ length: 4 }).map((_, index) => (
              <JobCardSkeleton key={index} />
            ))}
          </div>
        )}

        {!isLoading && jobs.length === 0 && (
          <p className="text-center text-xl text-muted-foreground mt-8">
            No jobs found. Try a different search term or category.
          </p>
        )}

        {!isLoading && !hasMore && jobs.length > 0 && (
          <p className="text-center text-xl text-muted-foreground mt-8">
            No more jobs to load.
          </p>
        )}
      </main>
    </div>
  );
}

export default SearchResults;