import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-4xl mx-auto rounded-2xl overflow-hidden">
        <CardHeader className="bg-primary text-primary-foreground p-6">
          <CardTitle className="text-3xl font-bold">Terms of Service</CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          <div className="prose prose-sm max-w-none">
            <p className="mb-4">
              Welcome to Jobsman. By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-2">Use of Service</h2>
            <p className="mb-4">
              You agree to use Jobsman for lawful purposes only and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-2">User Accounts</h2>
            <p className="mb-4">
              To access certain features of the website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information.
            </p>
            {/* Add more sections as needed */}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TermsOfService;