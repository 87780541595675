import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-4xl mx-auto rounded-2xl overflow-hidden">
        <CardHeader className="bg-primary text-primary-foreground p-6">
          <CardTitle className="text-3xl font-bold">Privacy Policy</CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          <div className="prose prose-sm max-w-none">
            <p className="mb-4">
              At Jobsman, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our service.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-2">Information Collection</h2>
            <p className="mb-4">
              We collect information that you provide directly to us, such as when you create an account, apply for a job, or contact us for support.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-2">Use of Information</h2>
            <p className="mb-4">
              We use the information we collect to provide, maintain, and improve our services, to process your job applications, and to communicate with you.
            </p>
            {/* Add more sections as needed */}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default PrivacyPolicy;