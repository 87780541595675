import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';

const About: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-4xl mx-auto rounded-2xl overflow-hidden">
        <CardHeader className="bg-primary text-primary-foreground p-6">
          <CardTitle className="text-3xl font-bold">About Jobsman</CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          <div className="prose prose-sm max-w-none">
            <p className="mb-4">
              Jobsman is a cutting-edge job search platform designed to connect talented professionals with their dream careers. Our mission is to simplify the job search process and help both job seekers and employers find their perfect match.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-2">Our Story</h2>
            <p className="mb-4">
              Founded in 2023, Jobsman has quickly become a leading name in the online job market. We leverage advanced technology and a user-friendly interface to provide a seamless job search experience.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-2">Why Choose Us</h2>
            <ul className="list-disc list-inside space-y-2 mb-4">
              <li>Curated job listings from top companies</li>
              <li>Advanced search and filtering options</li>
              <li>Personalized job recommendations</li>
              <li>Career resources and advice</li>
              <li>User-friendly interface for both job seekers and employers</li>
            </ul>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default About;