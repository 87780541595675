import React from 'react';
import { Link } from 'react-router-dom';
import { ThemeToggle } from './ThemeToggle';
import { Button } from "../components/ui/button";

const Navbar: React.FC = () => {
  return (
    <nav className="flex justify-between items-center p-4 bg-background border-b">
      <Link to="/" className="text-xl font-bold hover:text-primary transition-colors duration-200">Jobsman</Link>
      <div className="flex space-x-4 items-center">
        <Button variant="ghost" asChild>
          <Link to="/about">About</Link>
        </Button>
        <Button variant="ghost" asChild>
          <Link to="/contact">Contact</Link>
        </Button>
        <ThemeToggle />
      </div>
    </nav>
  );
};

export default Navbar;